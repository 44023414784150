$colorPrimary:#103aac;
$colorSecondary:#10ac34;
$colorHeader:#fff;
$colorFooter:#fff;

body {
  &.mmenu-opened {
    overflow: hidden;

    &:after {
      background: rgba(51, 51, 51, 0.9);
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 900;
      top: 0;
    }

    .page-wrapper {
      max-height: 100vh;
      overflow: hidden;
    }
  }

  .mmenu-wrapper {
    display: none;
    position: fixed;
    height: auto;
    width: 100%;
    z-index: 999;
    top: 0;
    overflow: auto;
    left: -100%;
    min-height: 100vh;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;

    &.opened {
      left: 0;
      max-width: 100%;

      .mmenu-header-close {
        position: fixed;
        height: auto;
        width: auto;
        top: 0;
        left: calc(100% - 8rem);
      }
    }

    .mmenu-content {
      width: 100%;
      max-width: calc(100% - 10rem);
      background: #fff;
      overflow: auto;
      max-height: 100vh;

      .mmenu-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        overflow-y: visible;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background: $colorHeader;
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;

        ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
        }

        .mmenu-header-language {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .switcher-trigger {
            padding-left: .5rem;
          }

          .switcher-options {
            ul {
              &.dropdown {
                height: auto;
              }

              li {
                &.view-de,
                &.view-en {
                  background: #fff;

                  a {
                    padding-left: .5rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      .mmenu-main {
        padding:0;

        .navigation {
          &.slide {
            overflow: hidden;

            .submenu {
              -webkit-transition: all ease 0.2s;
              transition: all ease 0.2s;
              display: block;
              position: absolute;
              overflow: hidden;
              max-width: 100%;
              width: 100%;
              border-top: 0;
              padding: 0;
              -webkit-box-shadow: none;
              box-shadow: none;
              top: 0;
              margin-top: 0;
              max-height: initial;
            }

            ul {
              -webkit-transition: all ease 0.2s;
              transition: all ease 0.2s;
              position: relative;
              left: 100%;
              padding:0;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;

              &.opened {
                overflow: hidden;
                left: 0;
                top: 0;
                height: 100%;
                background-color: #fff;
              }

              &.remove > li > a,
              &.remove > li.prev {
                position: absolute;
                left: -100%;
              }

              li {
                -webkit-transition: all ease 0.2s;
                transition: all ease 0.2s;
                left: 0;
                max-width: 100%;
                border-right: 0;
                background-color:$colorPrimary;
                margin-bottom: 2px;

                &.active {
                  border-top: 0;

                  & > a span:not(.toggle) {
                    text-decoration: underline;
                  }
                }

                &.prev {
                  background: $colorSecondary;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-pack: start;
                  -ms-flex-pack: start;
                  justify-content: flex-start;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                  flex-direction: row;
                  color: #fff;
                  font-size: 1.4rem;

                  .prev {
                    display: inline-block;
                    padding: 1rem 2rem;
                    width: auto;
                  }
                }

                a {
                  -webkit-transition: all ease 0.2s;
                  transition: all ease 0.2s;
                  left: 0;

                  &:hover {
                    text-decoration:none;
                  }
                }
              }
            }

            & > ul {
              position: relative;
              left: 0;

              li {
                a {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                  justify-content: space-between;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  padding: 0;
                  box-sizing: border-box;
                  font-weight: normal;
                }

                span {
                  padding: 1.2rem 2rem;
                  width: 100%;

                  &.toggle {
                    width: 50px;
                    height: 44px;
                    box-sizing: border-box;

                    &.next {
                      border-left: 2px solid #fff;

                      &:after {
                        content: "\f054";
                      }
                    }

                    &.prev {
                      border-right: 2px solid #fff;

                      &:after {
                        content: "\f053";
                      }
                    }

                    &:after {
                      font-family: "Font Awesome 5 Pro";
                      font-weight: 300;
                    }
                  }
                }
              }
            }

          }

          ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            &.level0 {
              padding-bottom: 2rem;
            }

            li {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              width: 100%;

              &.prev {
                span {
                  cursor: pointer;
                }
              }

              &.level0 {
                & > a {
                  padding: 2rem 3.5rem;
                }
              }

              a {
                color: #fff;
                width: 100%;

                &.level-top {
                  display: block;
                  font-size: 1.4rem;
                }

                &:after {
                  display: none;
                  -webkit-transition: all ease .2s;
                  transition: all ease .2s;
                }
              }
            }
          }
        }
      }

      .mmenu-footer {
        background: $colorFooter;
        padding: 0;
        border-top: 1px solid #fff;
        text-align: right;

        li {
          padding: 0 2rem;
          display: block;
          border-bottom: 1px solid #fff;

          a {
            font-size: 1.6rem;
            line-height: 4rem;
            color: #fff;
          }

          i {
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            font-size: 1.4rem;
            color: #fff;
            margin-right: 1rem;
          }
        }
      }

    }
  }

  .mmenu-header-close,
  .mmenu-top-close {
    position: relative;
    height: auto;
    width: auto;
    top: 2rem;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;

    .nav-toggle {
      background: transparent;
      border:0;
      cursor:pointer;

      &:before {
        display: none;
      }

      &::after {
        display: none;
      }

      span {
        background: transparent;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 4rem;
        color: #fff;
        line-height: normal;
        height: auto;
        position:static;

        &:before {
          content: "\f00d";
        }
      }
    }
  }
}
